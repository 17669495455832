<template>
<!-- 智慧化健身房—健身房 -->
    <div class="national">
        <div class="goBack">
            <div @click="getBack('peopleone')" style="cursor: pointer;">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span>{{pitchon}}</span>
        </div>
        <div class="posit-left">
            <!-- <announcement></announcement> -->
            <anletfcenter style="margin:0.22rem 0rem "></anletfcenter>
            <anleftbottom></anleftbottom>
        </div>
        <div class="people-top">
            <ancentertop style="margin-bottom:0.22rem;"></ancentertop>
            <ancenterbottom></ancenterbottom>
        </div>
        <div class="people-right">
            <anrighttop style="margin-bottom:0.30rem"></anrighttop>

        </div>
    </div>
</template>

<script>
import { show } from '../../assets/js/animate.js'
export default {
    components: {
        announcement: () => import('../charts/peoplethree/announcement.vue'),
        anletfcenter: () => import('../charts/peoplethree/anletfcenter.vue'),
        anleftbottom: () => import('../charts/peoplethree/anleftbottom.vue'),
        ancentertop: () => import('../charts/peoplethree/ancentertop.vue'),
        ancenterbottom: () => import('../charts/peoplethree/ancenterbottom.vue'),
        anrighttop: () => import('../charts/peoplethree/anrighttop.vue'),
        anrightbottom: () => import('../charts/peoplethree/anrightbottom.vue'),
    },
    data () {
        return {
            pitchon: "灵芝街道大树港智慧化健身房",
        }
    },
    methods: {
        getBack(showdata){
            let _this = this;
            show(showdata, _this);
            $('#amap').show();
            // this.plug.upDataMap(window.lotteryjson.home)
        },
    },
    mounted () {
        this.pitchon = window.jianshenfangData.name
    }
}
</script>

<style scoped>
.people-top{
    position: absolute;
    top:1.36rem;
    left:34.8%;
}
.people-right{
    position: absolute;
    top: 1.27rem;
    right:2.4%;
}
</style>